import * as React from 'react'

import { useMutation } from '@apollo/client'
import { CircularProgress, Stack } from '@mui/material'

import { ErrorDisplay, SeoHeaders } from 'shared/components'
import { getQueryStringValue } from 'shared/services'

import { ADMIN_CONFIRM_MUTATION } from '../../queries/auth'
import { setCredential } from '../../services/auth'

import type { AdminConfirmData, AdminConfirmVars } from '../../queries/auth'
import type { PageProps } from 'gatsby'

const Confirm = ({ location }: PageProps) => {
  const confirmationToken = getQueryStringValue(location.search, 'token', '')

  const [userConfirm, { data, loading, error }] =
    useMutation<AdminConfirmData, AdminConfirmVars>(ADMIN_CONFIRM_MUTATION, {
      variables: {
        confirmationToken,
      },
      errorPolicy: 'all',
    })

  React.useEffect(() => {
    userConfirm()
  }, [userConfirm])

  const credentials = data?.adminConfirmRegistrationWithToken?.credentials

  if (credentials) {
    setCredential(credentials)
    window.location.href = '/app/'
  }

  return (
    <React.Fragment>
      <SeoHeaders title='Confirmar email' />
      <Stack
        mx='auto'
        maxWidth='sm'
        minHeight='calc(100vh - 370px)'
        alignItems='center'
        justifyContent='center'
        paddingX={2}
        paddingY={6}
      >
        {loading && (
          <CircularProgress />
        )}
        <ErrorDisplay
          errorMsg={error?.message}
          mt={2}
        />
      </Stack>
    </React.Fragment>
  )
}

export default Confirm
